import Request from './../request.js';
import 'jquery.cookie';

class Payment
{
    static get $form() { return $('#sx-payment-form'); }
    static get $leftMenu() { return $('#patient_info').find('ul.nav-tabs > li>a[href="#payment-information"]').parent(); }
    static get $modal() { return $('#payment_modal'); }
    static get $recaptcha() { return $('.g-recaptcha'); }
    static get requiredFormFields() { return [ 'patientFirstName', 'patientLastName', 'email', 'phone', 'amountPaid']; }

    static checkRoute() {
        if (Request.getParam('sub') === 'make-a-payment') {
            Payment.activatePaymentSubMenu();
        }
    }

    static validate() {
        Payment.resetFormError();

        let errorCount = 0;

        errorCount += Payment.checkRequiredFields();
        errorCount += Payment.confirmEmails();
        errorCount += Payment.checkAmountToPay();
        errorCount += Payment.checkTerms();

        return errorCount;
    }

    static bindSubmit() {
        if (!Payment.$form.length) {
            return;
        }

        Payment.$form.on('submit', e => {
            if (Payment.validate() > 0) {
                e.preventDefault();
            }

            $.cookie('payment_confirm_email', Payment.$form.find('.confirm-email').eq(1).val());
            $.cookie('payment_is_agreed', Payment.$form.find('[name="i_agree"]').is(':checked'));
        });
    }

    static checkRequiredFields() {
        let errorCount = 0;

        Payment.requiredFormFields.forEach(field => {
            const $child = Payment.$form.find(`[name="payment[${field}]"]`);
            if ('hidden' !== $child.attr('type') && '' === $.trim($child.val())) {
                $child.closest('.input-group').addClass('error');
                errorCount += 1;
            }
        });

        return errorCount;
    }

    static checkAmountToPay() {
        const $amount = Payment.$form.find('[name="payment[amountPaid]"]');

        if ($amount.val().match(/[^0-9.]/g) || parseFloat($amount.val()) < 5 || parseFloat($amount.val()) > 99000) {
            $amount.closest('.input-group').addClass('error');
            return 1;
        }

        return 0;
    }

    static checkTerms() {
        const $terms = Payment.$form.find('[name="i_agree"]');

        if (!$terms.is(':checked')) {
            $terms.closest('.for_cb').find('label').addClass('error');
            return 1;
        }

        return 0;
    }

    static confirmEmails() {
        const $emails = Payment.$form.find('.confirm-email');

        if ($.trim($emails.eq(0).val()) !== $.trim($emails.eq(1).val())) {
            $emails.closest('.input-group').addClass('error');
            return 1;
        }

        return 0;
    }

    static handleServerResponse() {
        const $body = $('body');

        if (!$body.data('open-payment-modal')) {
            return;
        }

        Payment.openPaymentModal();
        Payment.resetFormError();

        if ($body.data('payment-success')) {
            Payment.displayMessage($body.data('payment-success-message'), true);
            $.removeCookie('payment_confirm_email');
            $.removeCookie('payment_is_agreed');

        } else {
            Payment.checkRecaptcha();
            Payment.checkIncompleteFormFields();
            Payment.recoverUnmappedFormFieldValues();
        }
    }

    static recoverUnmappedFormFieldValues()
    {
        const email = $.cookie('payment_confirm_email');
        const isAgreed = $.cookie('payment_is_agreed');

        if (email) {
            Payment.$form.find('.confirm-email').val(email);
        }
        if (isAgreed) {
            Payment.$form.find('[name="i_agree"]').prop('checked', true);
        }
    }

    static activatePaymentSubMenu() {
        Payment.$leftMenu.find('a').trigger('click');
    }

    static openPaymentModal() {
        Payment.activatePaymentSubMenu();
        Payment.$modal.modal('show');
    }

    static displayMessage(message, hideForm = false) {
        if (hideForm) {
            $('.payment-modal-form').hide();
        }
        $('.payment-modal-result-message').text(message).fadeIn(1000);
    }

    static checkIncompleteFormFields() {
        const requiredFields = Payment.$form.data('required-fields');

        if (!requiredFields) {
            return;
        }

        requiredFields.split(',').forEach(field => Payment.getFieldWrapper(field).addClass('error'));
    }

    static checkRecaptcha() {
        if (Payment.$form.data('recaptcah-error')) {
            Payment.$recaptcha.addClass('error');
        }
    }

    static getFieldWrapper(fieldName) {
        return Payment.$form.find(`[name="payment[${fieldName}]"]`).closest('.input-group');
    }

    static resetFormError() {
        Payment.$recaptcha.removeClass('error');
        Payment.$form.find('[name="i_agree"]').closest('.for_cb').find('label').removeClass('error');
        Payment.$form.find('.input-group').removeClass('error');
    }
}

export default Payment;